<template>
  <div class="app buy-goods">
    <div class="common-content">
      <el-row :gutter="20" class="goods-item" type="flex">
        <el-col :xs="24" :sm="12" :md="16" :lg="13" :xl="16" class="goods-item-img flex">
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <img :src="detail.cover" alt>
          </el-col>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <h3>{{detail.title}}</h3>
            <p>{{detail.digest}}</p>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="12" :md="5" :lg="10" :xl="5" class="goods-item-desc">
          <h5>
            小计：
            <span class="goods-price">{{detail.price}}</span>
          </h5>
        </el-col>
      </el-row>
    </div>
    <div class="goods-count common-content">
      <p>
        可用积分：
        <span>{{detail.remain_integral}}</span>分
      </p>
      <p>
        积分抵用：
        <span>{{deduction}}</span>元
        <svg class="icon myIconStyle" aria-hidden="true">
          <use xlink:href="#icon-duihao2"></use>
        </svg>
      </p>
      <p>
        应付：
        <span class="goods-price">{{money}}</span>
        元
      </p>
      <p>
        <el-button type="danger" @click="pay(detail.id)">立即购买</el-button>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      detail: {},
      // 可抵扣金钱
      deduction: '',
      // 应付价钱
      money: ''
    }
  },
  methods: {
    // 获取文章详情
    getDetail (id) {
      this.$store.dispatch('getArticleDetail', id).then(res => {
        this.detail = res
        // let shopIntegral = res.price * 100
        if (res.remain_integral === 0) {
          this.deduction = res.remain_integral
          this.money = res.price
        // } else if (res.remain_integral - shopIntegral >= 0) {
        } else if (res.remain_integral - res.price >= 0) {
          // this.deduction = Math.ceil(shopIntegral / 100)
          this.deduction = res.price
          this.money = 0
        } else {
          // this.deduction = Math.ceil(res.remain_integral / 100)
          this.deduction = 0
          // this.money = (shopIntegral - res.remain_integral) / 100
          this.money = res.price
        }
      })
    },
    // 提交订单
    pay (id) {
      let obj = {
        article_id: id
      }
      this.$store.dispatch('subscribe', obj).then(res => {
        console.log(res)
        console.log(res.data.remain)
        if (res.data.remain > 0) {
          let params = {
            orderId: res.data.order_no,
            articleId: id
          }
          this.$store.commit('getParams', params)
          this.$router.push({ name: 'Paygoods' })
        } else {
          this.$router.go(-1)
        }
      })
    }
  },
  mounted () {
    this.getDetail(this.$route.params.id)
  }
}
</script>
<style lang="less">
  .icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  .goods-price {
    color: @global-red;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .buy-goods {
    margin-bottom: 3rem;
    width: 100%;
    background: @global-personal-background;
    padding-top: 50px;
    .goods-item {
      margin: 0 !important;
      background: @global-white;
      padding: 20px 10px;
      flex-wrap: wrap;
      .goods-item-img {
        background: @global-personal-background;
        text-align: left;
        padding:20px 10px;
        img {
          width: 100%;
        }
      }
      .goods-item-desc {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        h5 {
          background-color: @global-white;
          margin: 0;
          padding: 0.5rem;
          text-align: right;
        }
      }
    }
    .goods-count {
      text-align: right;
      padding: 1rem;
    }
  }
</style>
